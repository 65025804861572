import React from "react";
import "./Icon.scss";

// Enum for Icon Names
export enum IconName {
  CommandCenter = "command_center",
  Applications = "applications",
  Proposals = "proposals",
  Brokers = "brokers",
  Companies = "companies",
  Dashboard = "dashboard",
  Affiliates = "affiliates",
  ProductBuilder = "product_builder",
  FileUpload = "file_upload",
  Campaigns = "campaigns",
  IncreaseOption = "increase_option",
  Help = "help",
  Plus = "plus",
  Users = "users",
}

// import SVG icons
const icons: Record<IconName, React.FC> = {
  [IconName.CommandCenter]: require("../../assets/icons/command_center.svg").ReactComponent,
  [IconName.Applications]: require("../../assets/icons/applications.svg").ReactComponent,
  [IconName.Proposals]: require("../../assets/icons/proposals.svg").ReactComponent,
  [IconName.Brokers]: require("../../assets/icons/brokers.svg").ReactComponent,
  [IconName.Companies]: require("../../assets/icons/companies.svg").ReactComponent,
  [IconName.Dashboard]: require("../../assets/icons/dashboard.svg").ReactComponent,
  [IconName.Affiliates]: require("../../assets/icons/affiliates.svg").ReactComponent,
  [IconName.ProductBuilder]: require("../../assets/icons/product_builder.svg").ReactComponent,
  [IconName.FileUpload]: require("../../assets/icons/file_upload.svg").ReactComponent,
  [IconName.Campaigns]: require("../../assets/icons/campaigns.svg").ReactComponent,
  [IconName.IncreaseOption]: require("../../assets/icons/increase_option.svg").ReactComponent,
  [IconName.Help]: require("../../assets/icons/help.svg").ReactComponent,
  [IconName.Plus]: require("../../assets/icons/plus.svg").ReactComponent,
  [IconName.Users]: require("../../assets/icons/users.svg").ReactComponent,
};

interface IconProps {
  name: IconName;
  size?: string;
}

const Icon: React.FC<IconProps> = ({ name, size = "24px", ...props }) => {
  const SvgIcon = icons[name];

  if (!SvgIcon) {
    return;
  }

  return (
    <div className="icon-wrapper" style={{ width: size, height: size }}>
      <SvgIcon {...props} />
    </div>
  );
};

export default Icon;
