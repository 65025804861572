import React from "react";
import Icon, { IconName } from "src/components/Icon/Icon";
import * as S from "../../constants/StringConstants";

const CommandCenter = () => {
  return (
    <>
      <div className="table-view__header-buttons-container">
        <div className="table-view__header-container">
          <div className="table-view__icon-container">
            <Icon name={IconName.CommandCenter} size="16px" />
          </div>

          <h1 className="table-view__title">{S.CMD_CENTER_PAGE_TITLE}</h1>
        </div>
      </div>
    </>
  );
};

export default CommandCenter;
