import axios from "axios";
import * as React from "react";
import { connect } from "react-redux";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";
import Icon, { IconName } from "src/components/Icon/Icon";
import { IRootState } from "src/store/reducers";
import { fetchUnreadFilesCount } from "src/store/reducers/secureUpload";
import GuardedComponent from "../../components/GuardedComponent/GuardedComponent";
import * as S from "../../constants/StringConstants";
import { IUser, UserRole } from "../../store/models/user.model";
import { logout } from "../../store/reducers/authentication";
import "./AppSideNav.scss";

const clientVersion = require("../../version.json");

const enableApps = false;

interface IAppSideNavProps extends StateProps, DispatchProps, RouteComponentProps {
  user: IUser;
}

export interface AppSideNavState {
  dropdownOpen?: boolean;
}

const proposalsPath = "/proposals/all";
const brokersPath = "/brokers/all";
const companiesPath = "/companies/all";
const affiliatesPath = "/affiliates/all";
const productsPath = "/products/all";
const fileUploadPath = "/file-upload";
const campaignPath = "/campaigns/all";
const dashboardPath = "/dashboard";
const commandCenterPath = "/command-center";
const applicationsPath = "/applications";

class AppSideNav extends React.Component<IAppSideNavProps, AppSideNavState> {
  constructor(props) {
    super(props);
    this.state = { dropdownOpen: false };
    this.toggle = this.toggle.bind(this);
  }

  async componentDidMount() {
    await this.props.fetchUnreadFilesCount();
  }

  toggle() {
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen,
    }));
  }

  displayVersion = async () => {
    let s = "";
    try {
      s = `Client version: ${clientVersion.version} (${clientVersion.build})`;
    } catch (e) {}
    try {
      const { data: serverVersion } = await axios.get("../version.json");
      s = `${s}\nServer Information: ${serverVersion.version} (${serverVersion.build})`;
    } catch (e) {}
    if (s.length) {
      window.alert(s);
    }
  };

  isActive = (path: string): boolean => {
    const currentPath = this.props.location.pathname.split("/")[1];
    const targetPath = path.split("/")[1];

    // Check if the first segments match
    return currentPath === targetPath;
  };

  isActiveClassName = (path: string): string => {
    return this.isActive(path) ? "isActive" : "";
  };

  logout = () => {
    this.props.logout();
  };

  navigateToProfile = () => {
    this.props.history.push(`/profile`);
  };

  navigateToHealthCheck = () => {
    this.props.history.push("/health-check");
  };

  navigateToUsers = () => {
    this.props.history.push("/users/all");
  };

  render() {
    const { user, unreadCount } = this.props;

    return (
      <aside className="side-nav">
        <div className="side-nav__logo" />

        <div className="side-nav__links">
          <GuardedComponent
            authorizedRoles={[
              UserRole.Admin,
              UserRole.ProposalSpecialist,
              UserRole.RegionalBranchManager,
              UserRole.BranchManager,
              UserRole.UnderwritingAssociate,
            ]}>
            {enableApps && (
              <>
                <Link className={this.isActiveClassName(commandCenterPath)} to={commandCenterPath}>
                  <Icon name={IconName.CommandCenter} />
                  {S.NAV_COMMAND_CENTER}
                </Link>

                <Link className={this.isActiveClassName(applicationsPath)} to={applicationsPath}>
                  <Icon name={IconName.Applications} />
                  {S.NAV_APPLICATIONS}
                </Link>
              </>
            )}

            <Link className={this.isActiveClassName(proposalsPath)} to={proposalsPath}>
              <Icon name={IconName.Proposals} />

              {S.NAV_PROPOSALS}
            </Link>

            <Link className={this.isActiveClassName(brokersPath)} to={brokersPath}>
              <Icon name={IconName.Brokers} />

              {S.NAV_BROKERS}
            </Link>

            <Link className={this.isActiveClassName(companiesPath)} to={companiesPath}>
              <Icon name={IconName.Companies} />

              {S.NAV_COMPANIES}
            </Link>
          </GuardedComponent>

          <GuardedComponent authorizedRoles={[UserRole.Admin]}>
            <Link className={this.isActiveClassName(dashboardPath)} to={dashboardPath}>
              <Icon name={IconName.Dashboard} />

              {S.NAV_DASHBOARD}
            </Link>
          </GuardedComponent>

          <GuardedComponent authorizedRoles={[UserRole.Admin, UserRole.RegionalBranchManager]}>
            <Link className={this.isActiveClassName(affiliatesPath)} to={affiliatesPath}>
              <Icon name={IconName.Affiliates} />

              {S.NAV_AFFILIATES}
            </Link>
          </GuardedComponent>

          <GuardedComponent authorizedRoles={[UserRole.Admin]}>
            <Link className={this.isActiveClassName(productsPath)} to={productsPath}>
              <Icon name={IconName.ProductBuilder} />

              {S.NAV_PRODUCTS}
            </Link>
          </GuardedComponent>

          <GuardedComponent
            authorizedRoles={[
              UserRole.Admin,
              UserRole.RegionalBranchManager,
              UserRole.ProposalSpecialist,
              UserRole.BranchManager,
              UserRole.UnderwritingAssociate,
            ]}>
            <Link className={this.isActiveClassName(fileUploadPath)} to={fileUploadPath}>
              <Icon name={IconName.FileUpload} />

              {S.NAV_SECURE_UPLOAD}

              {unreadCount > 0 ? <span className="side-nav__badge">{unreadCount}</span> : null}
            </Link>
          </GuardedComponent>

          <GuardedComponent authorizedRoles={[UserRole.Admin]}>
            <Link className={this.isActiveClassName(campaignPath)} to={campaignPath}>
              <Icon name={IconName.Campaigns} />

              {S.NAV_CAMPAIGN}
            </Link>
          </GuardedComponent>
        </div>
        <div className="side-nav__footer">
          <Dropdown direction="up" isOpen={this.state.dropdownOpen} toggle={this.toggle}>
            <DropdownToggle caret>
              <div>
                {user.firstName} {user.lastName}
              </div>

              <div className="user-email">{user.email}</div>
            </DropdownToggle>

            <DropdownMenu>
              <DropdownItem onClick={this.navigateToProfile}>{S.NAV_PROFILE}</DropdownItem>
              <DropdownItem onClick={this.logout}>{S.NAV_LOGOUT}</DropdownItem>
              <DropdownItem onClick={this.navigateToHealthCheck}>{S.NAV_HEALTH_CHECK_UPLOAD}</DropdownItem>
              <DropdownItem onClick={this.navigateToUsers}>{S.NAV_USERS}</DropdownItem>
              <DropdownItem className="side-nav__version" onClick={this.displayVersion}>
                Version: {clientVersion.version}
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </div>
      </aside>
    );
  }
}

const mapStateToProps = ({ auth, SecureUploadState }: IRootState) => ({
  user: auth.user,
  unreadCount: SecureUploadState.unreadCount,
});

const mapDispatchToProps = { logout, fetchUnreadFilesCount };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AppSideNav));
