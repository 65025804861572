import { get } from "lodash";
import React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";

import * as S from "../../constants/StringConstants";
import { IAffiliate } from "../../store/models/affiliate.model";
import { IRootState } from "../../store/reducers";
import { fetchAffiliatesList, removeAffiliate } from "../../store/reducers/affiliates";

import { Button } from "reactstrap";
import GuardedComponent from "src/components/GuardedComponent/GuardedComponent";
import Icon, { IconName } from "src/components/Icon/Icon";
import { UserRole } from "src/store/models/user.model";
import { hasAnyAuthority } from "src/util/roleAuthorization";
import { openUrl } from "src/util/util.navigation";
import { renderAddress, renderPrimaryContact } from "../../components/Table/columnRenders";
import SearchFieldTool from "../../components/Table/SearchField";
import DisTable from "../../components/Table/Table";

interface IAffiliatesProps extends StateProps, DispatchProps, RouteComponentProps {}

class Affiliates extends React.Component<IAffiliatesProps> {
  render() {
    const {
      auth,
      fetchAffiliatesList,
      removeAffiliate,
      affiliates: { map, projection, total, listOptions },
    } = this.props;
    const isAdmin = hasAnyAuthority(auth.user.userRole, [UserRole.Admin]);
    const columns = [
      {
        key: "name",
        title: S.AFF_COL_NAME,
        style: { minWidth: "12rem" },
      },
      {
        key: "address",
        title: S.AFF_COL_ADDRESS,
        render: item => renderAddress(item.address),
        style: { minWidth: "12rem" },
      },
      {
        key: "branch",
        title: S.AFF_COL_BRANCH,
        style: { minWidth: "12rem" },
      },
      {
        key: "phoneNumber",
        title: S.AFF_COL_PHONE,
        style: { minWidth: "12rem" },
      },
      {
        key: "primaryContact",
        title: S.AFF_COL_PRIMARY_CONTACT,
        render: item => renderPrimaryContact(item.primaryContact || (item.contacts && item.contacts[0])),
        style: { minWidth: "12rem" },
      },
      {
        key: "affiliateID",
        title: S.AFF_COL_AFFILIATE_ID,
        style: { minWidth: "12rem" },
      },
      {
        key: "externalID",
        title: S.AFF_COL_EXTERNAL_ID,
        style: { minWidth: "12rem" },
      },
      ...(isAdmin
        ? [
            {
              key: "actions",
              title: S.AFF_DELETE,
              style: { minWidth: "8rem" },
              render: item => (
                <Button
                  onClick={async e => {
                    e.stopPropagation();
                    if (confirm(S.AFF_CONFIRM_DELETE)) {
                      await removeAffiliate(item.affiliateID);
                      await fetchAffiliatesList();
                    }
                  }}>
                  {S.AFF_DELETE}
                </Button>
              ),
            },
          ]
        : []),
    ];

    return (
      <div className="table-view__content">
        <div className="table-view__header-buttons-container">
          <div className="table-view__header-container">
            <div className="table-view__icon-container">
              <Icon name={IconName.Affiliates} size="16px" />
            </div>

            <h1 className="table-view__title">{S.AFF_PAGE_TITLE}</h1>
          </div>

          <div className="table-view__buttons-container">
            <GuardedComponent
              authorizedRoles={[
                UserRole.Admin,
                UserRole.ProposalSpecialist,
                UserRole.BranchManager,
                UserRole.UnderwritingAssociate,
              ]}>
              <button className="side-nav__add-button" onClick={() => openUrl("/affiliates/new")}>
                <Icon name={IconName.Plus} />

                {S.NEW_AFFILIATE}
              </button>
            </GuardedComponent>
          </div>
        </div>

        <DisTable<IAffiliate>
          columns={columns}
          onRowClick={(data: IAffiliate) => {
            this.props.history.push(`/affiliates/${data.affiliateID}`);
          }}
          initialState={{ rows: 25, map, projection, total, ...listOptions }}
          onUpdate={async ({ page, rows, sort, query }) => {
            const result: any = await fetchAffiliatesList({ page, rows, sort, query });
            return {
              total: get(result, "value.data.totalRows") as number,
              data: get(result, "value.data.items") as IAffiliate[],
            };
          }}>
          <SearchFieldTool />
        </DisTable>
      </div>
    );
  }
}

const mapStateToProps = ({ affiliates, auth }: IRootState) => ({ affiliates, auth });
const mapDispatchToProps = { fetchAffiliatesList, removeAffiliate };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(Affiliates);
