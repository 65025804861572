import React from "react";
import { Field, FieldProps, FieldRenderProps } from "react-final-form";
import { InputGroup, InputGroupText } from "reactstrap";
import "./TextField.scss";

/**
 * label - label decoration for describing the text input content. ex: "username" or "email";
 * light - assign true to render light themed text field component.
 * appendText - static text content displayed after the input field. ex: "_______ lbs" or "________ meters";
 * prependText - static text content displayed before the input field. ex: "$ _______"
 */
export interface ITextFieldProps<FieldValue, El extends HTMLInputElement>
  extends FieldProps<FieldValue, FieldRenderProps<FieldValue, El>> {
  label?: string;
  light?: boolean;
  type?: string;
  appendText?: string;
  prependText?: string;
  placeholder?: string;
}

export default class TextField<El extends HTMLInputElement> extends React.Component<ITextFieldProps<string, El>> {
  render() {
    const { label, light, appendText, prependText, disabled, placeholder, ...fieldProps } = this.props;

    return (
      <Field {...fieldProps} component="input">
        {({ input, meta }) => {
          return (
            <div
              className={
                disabled
                  ? "text-field-disabled"
                  : meta.touched && meta.error
                    ? "text-field-error"
                    : light
                      ? "text-field-light"
                      : "text-field-dark"
              }>
              <ul>
                {label && (
                  <li>
                    <label>{label}</label>
                  </li>
                )}
                <li>
                  <InputGroup>
                    {prependText && <InputGroupText>{prependText}</InputGroupText>}
                    <input disabled={disabled} placeholder={placeholder} {...input} />
                    {meta.touched && meta.error && <span>{meta.error}</span>}
                    {appendText && <InputGroupText>{appendText}</InputGroupText>}
                  </InputGroup>
                </li>
              </ul>
            </div>
          );
        }}
      </Field>
    );
  }
}
