/**
 * Opens a specified URL, optionally in a new browser tab
 *
 * @param url - The URL to open
 * @param newTab - Whether to open the URL in a new browser tab. Defaults to `false`
 */

export const openUrl = (url: string, newTab: boolean = false): void => {
  if (newTab) {
    window.open(url, "_blank");
  } else {
    window.location.href = url;
  }
};
